import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './login.css'; // Make sure to import the CSS file


const ResendConfirmation = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';
  const backend_url = process.env.REACT_APP_BACKEND_URL;

  const handleResend = (e) => {
    e.preventDefault();
    fetch(`${backend_url}/resend-confirmation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      setMessage(data.message);
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage('Error resending confirmation email. Please try again later.');
    });
  };

  return (
    <div className="auth-form">
      <h2>Email Not Confirmed</h2>
      <p>Your email address has not been confirmed yet. Please check your inbox for the confirmation email.</p>
      <form onSubmit={handleResend}>
        <button type="submit">Resend Confirmation Email</button>
      </form>
      {message && <p>{message}</p>}
      <button onClick={() => navigate('/login')}>Back to Login</button>
    </div>
  );
};

export default ResendConfirmation;
