import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './resetPassword.css';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backend_url}/forgot-password`, { 
       // Update to match the Flask route
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      setMessage(data.message || 'If this email is registered, you will receive a password reset link.');
      if (data.message === 'Password reset email sent') {
        setTimeout(() => navigate('/login'), 3000);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
    });
  };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Forgot Password</h2>
      <p style={{ textAlign: "center" }}>
        <small>Enter your email address below and we'll send you a link to reset your password.</small>
      </p>
      <div className="form-group">
        <input name="email" type="email" placeholder="Email" onChange={handleChange} required />
      </div>
      <button type="submit">Send Reset Link</button>
      {message && <p className="message">{message}</p>}
    </form>
  );
};

export default ForgotPassword;
