import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import io from 'socket.io-client';
import Cookies from 'js-cookie';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const socket = io(`${backend_url}`);
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [userGender, setUserGender] = useState(null);
    const [connected, setConnected] = useState(false);
    const [storedNotifications, setStoredNotifications] = useState(null);
    const [currentUsername, setCurrentUsername] = useState(null);

    const navigate = useNavigate();

    const login = (token, refreshToken) => {
        setIsAuthenticated(true);
        Cookies.set('token', token, { secure: true, sameSite: 'none' }); // Set secure and sameSite attributes appropriately
        Cookies.set('refreshToken', refreshToken, { secure: true, sameSite: 'none' }); // Set secure and sameSite attributes appropriately
        fetchUserDetails(token);
    };

    const fetchUserDetails = (token) => {
        fetch(`${backend_url}/user`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(userResponse => userResponse.json())
        .then(userData => {
            setCurrentUserId(userData.user.id);
            setUserGender(userData.user.gender);
            const username = userData.user.id;
            setCurrentUsername(username);
            socket.emit('register', { username });
            setConnected(true);
            socket.on('stored_notifications', (data) => {
                setStoredNotifications(data.map(notif => ({ ...notif, processed: false })));
            });
        })
        .catch((error) => {
            console.error('Error fetching user details:', error);
        });
    };

    const logout = () => {
        setIsAuthenticated(false);
        setCurrentUserId(null);
        setUserGender(null);
        setConnected(false);
        Cookies.remove('token', { secure: true, sameSite: 'none' }); // Set secure and sameSite attributes appropriately
        Cookies.remove('refreshToken', { secure: true, sameSite: 'none' }); // Set secure and sameSite attributes appropriately
        navigate('/login');
    };

    const checkAuth = () => {
        const token = Cookies.get('token');
        if (token) {
            try {
                const user = jwtDecode(token);
                setIsAuthenticated(true);
                setCurrentUserId(user.sub);
                setUserGender(user.gender);
                // Check if the token is about to expire
                const { exp } = user;
                const currentTime = Date.now() / 1000;
                if (exp - currentTime < 600) { // less than 10 minutes
                    refreshAccessToken();
                } else {
                    fetchUserDetails(token);
                }
            } catch (error) {
                console.error('Invalid token:', error);
                logout();
            }
        }
    };

    const refreshAccessToken = () => {
        const refreshToken = Cookies.get('refreshToken');
        fetch(`${backend_url}/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            const { access_token } = data;
            Cookies.set('token', access_token, { secure: true, sameSite: 'none' }); // Set secure and sameSite attributes appropriately
            fetchUserDetails(access_token);
        })
        .catch(error => {
            console.error('Error refreshing token:', error);
            logout();
        });
    };

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        if (isAuthenticated && !currentUserId) {
            checkAuth();
        }
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, currentUserId, currentUsername, userGender, login, logout, checkAuth, connected, socket, storedNotifications }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
