import React from 'react'
import "./landingpage.css"
import dating from '../../assets/dating.png'
import homeimg from '../../assets/heroimg.png'
import { Link} from 'react-router-dom';


const LandingPage = () => {
  return (
    <div className="LandingPage">

      <section className="hero-section">
        <div>
          <img src={dating} alt="Dating" className="hero-dating-image" />
          <div className="hero-text">
            <h1>Dating for Grown Ups</h1>
            <p>Make a Real Connection</p>
            <Link to="/signup" className="nav-button">Register</Link>
          </div>
        </div>
        <img src={homeimg} alt="exploreimg" className='homeimg'/>     
      </section>


    </div>
  );
};

export default LandingPage;
