import React from 'react';
import './policies.css'; // Make sure to style your modal appropriately

const DisclaimerModal = ({ isOpen, onClose, onAccept }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>Disclaimer</h2>
                <p>Welcome to www.huppypeople.com (Website/App), where you can connect with others in a safe and anonymous environment. Before you proceed, please take a moment to review the following disclaimer:</p>
                <ul>
                    <li>Anonymous Interactions: Huppy People facilitates anonymous interactions between users. While we strive to provide a safe platform, we cannot guarantee the authenticity or intentions of other users.</li>
                    <li>Use at Your Own Risk: Your use of Huppy people is at your own risk. We strongly advise exercising caution and discretion when interacting with other users. Verify the identity of users independently before sharing personal information.</li>
                    <li>Age Restrictions: Users must be at least 21 years old to use Huppy people. By using this platform, you confirm that you meet this age requirement.</li>
                    <li>No Background Checks: Huppy people does not conduct background checks on its users. It is your responsibility to conduct any necessary background checks or investigations before meeting or engaging with other users in person.</li>
                    <li>Reporting Misconduct: If you encounter any suspicious or inappropriate behavior on Huppy People, please report it to us immediately. We take all reports seriously and will take appropriate action.</li>
                    <li>No Guarantee of Matches: While Huppy People aims to facilitate connections between users, we do not guarantee that you will find suitable matches or that your interactions will lead to meaningful relationships.</li>
                    <li>Third-Party Content: Huppy People may contain links to third-party websites or content. We are not responsible for the accuracy, legality, or content of any third-party websites or resources.</li>
                    <li>Modification of Terms: We reserve the right to modify or update these terms and conditions at any time without prior notice. By continuing to use Huppy People, you agree to be bound by the latest version of these terms.</li>
                </ul>
                <p>By using Huppy People on either or both [Website/App], you acknowledge that you have read, understood, and agreed to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using Huppy People on either or both [Website/App].</p>
                <button onClick={onAccept}>Continue</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default DisclaimerModal;
