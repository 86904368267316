import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const backend_url =  process.env.REACT_APP_BACKEND_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${backend_url}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json().then(data => ({ status: response.status, body: data })))
    .then(({ status, body }) => {
      if (status === 200 && body.access_token) {
        const token = body.access_token;
        login(token);
        fetch(`${backend_url}/user`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        .then(userResponse => userResponse.json())
        .then(userData => {
          const { gender } = userData.user;
          if (gender === 'Male') {
            navigate('/maleHomepage');
          } else if (gender === 'Female') {
            navigate('/femaleHomepage');
          } else {
            navigate('/');
          }
        })
        .catch((error) => {
          setErrorMessage('Error fetching user details');
        });
      } else if (status === 403 && body.message === 'Please confirm your email address first') {
        navigate('/resend-confirmation', { state: { email: formData.email } });
      } else {
        setErrorMessage(body.error || body.message || 'Login failed');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setErrorMessage('Error logging in');
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Welcome Back</h2>
      <p style={{ textAlign: "center" }}>
        <small>Hey there! Ready to log in? Just enter your Email and password below
          and you'll be back in action in no time. Let's go!</small>
      </p>
      <div className="form-group">
        <input name="email" type="email" placeholder="Email" onChange={handleChange} required />
      </div>
      <div className="form-group password-group">
        <input
          name="password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={handleChange}
          required
        />
        <span className="toggle-password" onClick={toggleShowPassword}>
          <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
        </span>
      </div>
      <button type="submit">Login</button>
      <div style={{ textAlign: 'center'}}>
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      {errorMessage && <p className="error-message" style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
    </form>
  );
};

export default Login;
