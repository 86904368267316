import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './memberpage.css';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
const backend_url = process.env.REACT_APP_BACKEND_URL;
const socket = io(`${backend_url}`);

const MemberPage = () => {
    const { userId } = useParams();
    const { currentUsername, currentUserId } = useAuth();
    const [memberData, setMemberData] = useState(null);
    const [buttonState, setButtonState] = useState('default');
    const [notification, setNotification] = useState('');
    const token = Cookies.get('token');
    const navigate = useNavigate();
    const [ membersUsername, setMembersUsername] = useState(null); 

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${backend_url}/profile/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate('/login');
                    }
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setMemberData(data);
                setMembersUsername(data.user.first_name + ' ' + data.user.last_name);

                // Check connection status
                const connectionResponse = await fetch(`${backend_url}/check_connection?user1_id=${currentUserId}&user2_id=${data.user.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!connectionResponse.ok) {
                    if (connectionResponse.status === 401) {
                        navigate('/login');
                    }
                    throw new Error('Failed to check connection');
                }
                const connectionData = await connectionResponse.json();
        
                if (connectionData.connected) {
                    setButtonState('connected');
                } else {
                    setButtonState('default');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();

        socket.on('receive_request', (data) => {
            setNotification(`Connection request from ${data.requester}`);
        });

        socket.on('request_accepted', async (data) => {
            setNotification(`Your request to ${data.receiver} was accepted.`);
            setButtonState('connected');
        });

        socket.on('request_rejected', (data) => {
            setNotification(`Your request to ${data.receiver} was rejected.`);
            setButtonState('default');
        });

        return () => {
            socket.off('receive_request');
            socket.off('request_accepted');
            socket.off('request_rejected');
        };
    }, [token, userId, currentUsername, navigate]);

    const receive = memberData ? memberData.user.id: null;
    const sendRequest = () => {
        socket.emit('send_request', { requester: currentUserId, receiver: receive });
        setButtonState('pending');
    };

    return (
        <div className="profile-container">
            {memberData ? (
                <>
                    <div className="profile-header">
                        <div className="profile-info">
                            <img src={memberData.profile.image_url} alt="Profile" className="profile-img" />
                            <div className='memberprof'>
                                <h2>{memberData.user.first_name} {memberData.user.last_name}</h2>
                                <p><strong>{memberData.profile.profession}</strong></p>
                                <p><strong>{memberData.user.gender}</strong></p>
                                {buttonState === 'default' && (
                                    <button className="edit-profile-btn" onClick={sendRequest}>Connect</button>
                                )}
                                {buttonState === 'pending' && (
                                    <button className="edit-profile-btn" disabled>Request Pending</button>
                                )}
                                {buttonState === 'connected' && (
                                    <a href={`https://wa.me/${memberData.user.whatsapp}`} target='_blank' rel='noopener noreferrer' style={{ textDecoration: "None" }}>
                                        <button className="edit-profile-btn">Connect on WhatsApp</button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='profile'>
                            <div className="profile-details">
                                <div className="profile-details-item"><strong>Complexion:</strong> {memberData.profile.complexion}</div>
                                <div className="profile-details-item"><strong>Body Shape:</strong> {memberData.profile.body_shape}</div>
                                <div className="profile-details-item"><strong>Height:</strong> {memberData.profile.height}</div>
                                <div className="profile-details-item"><strong>Age Group:</strong> {memberData.profile.age_group}</div>
                                <div className="profile-details-item"><strong>Profession:</strong> {memberData.profile.profession}</div>
                                <div className="profile-details-item"><strong>Country of Residence:</strong> {memberData.profile.country_of_residence}</div>
                                {memberData.profile.countryOfResidence === 'Kenya' && (
                                    <>
                                      <div className="profile-details-item">
                                        <strong>County of Residence:</strong> {memberData.profile.residency}
                                      </div>
                                      <div className="profile-details-item">
                                        <strong>Constituency:</strong> {memberData.profile.constituency}
                                      </div>
                                    </>
                                )}
                                <div className="profile-details-item"><strong>Sexuality:</strong> {memberData.profile.sexuality}</div>
                                <div className="profile-details-item"><strong>Ethnicity:</strong> {memberData.profile.ethnicity}</div>
                                <div className="profile-details-item"><strong>Religion:</strong> {memberData.profile.religion}</div>
                                <div className="profile-details-item"><strong>Turn Off:</strong> {memberData.profile.turnoff}</div>
                                <div className="profile-details-item"><strong>Number of Kids:</strong> {memberData.profile.number_of_kids}</div>
                                <div className="profile-details-item"><strong>Education:</strong> {memberData.profile.education}</div>
                                <div className="profile-details-item"><strong>HIV Status:</strong> {memberData.profile.hiv_status}</div>
                                <div className="profile-details-item"><strong>Dreadlock:</strong> {memberData.profile.dreadlock}</div>
                            </div>
                            <div className="profile-details-item reason-for-searching"><strong>Reason:</strong> {memberData.profile.reason}</div>
                        </div>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default MemberPage;
