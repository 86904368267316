import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './homepages.css';
import femalelanding from '../../assets/femalelanding.png';
import femalehero from '../../assets/heroimg.png';
import Search from '../../components/searchandform/SearchBar'; // Adjust the import path as needed
import Cookies from 'js-cookie';
import malehero from '../../assets/heroimg.png';
import {
  ageGroupOptions,
  complexionOptions,
  bodyShapeOptions,
  heightOptions,
  countryOptions,
  countiesWithConstituencies,
  sexualityOptions,
  reasonOptions,
  ethnicityOptions,
  religionOptions
} from '../../components/searchandform/formOptions'; // Adjust the import path as needed


const FemaleHomepage = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    countryOfResidence: '',
    countyOfResidence: '',
    height: '',
    complexion: '',
    bodyShape: '',
    ageGroup: '',
    sexuality: '',
    reason: '',
    ethnicity: '',
    religion: '',
    searchQuery: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const itemsPerPage = 18; // Adjust as needed
  const backend_url = process.env.REACT_APP_BACKEND_URL;


  useEffect(() => {
    fetchData();
  }, [filters, currentPage]);

  const fetchData = () => {
    const { countryOfResidence, countyOfResidence, height, complexion, bodyShape, ageGroup, sexuality, reason, ethnicity, religion, searchQuery } = filters;
    let url = `${backend_url}/profiles/male?page=${currentPage}&per_page=${itemsPerPage}`;

    if (countryOfResidence) url += `&country_of_residence=${countryOfResidence}`;
    if (countyOfResidence) url += `&county_of_residence=${countyOfResidence}`;
    if (height) url += `&height=${height}`;
    if (complexion) url += `&complexion=${complexion}`;
    if (bodyShape) url += `&body_shape=${bodyShape}`;
    if (ageGroup) url += `&age_group=${ageGroup}`;
    if (sexuality) url += `&sexuality=${sexuality}`;
    if (reason) url += `&reason=${reason}`;
    if (ethnicity) url += `&ethnicity=${ethnicity}`;
    if (religion) url += `&religion=${religion}`;
    if (searchQuery) url += `&search=${searchQuery}`;

    const token = Cookies.get('token');

    fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => {
        if (response.status === 401) {
          navigate('/login');
        }
        return response.json();
      })
      .then(result => {
        setData(result.profiles);
        setFilteredData(result.profiles);
        setTotal(result.total);
        setCurrentPage(result.page);
      })
      .catch(error => console.error('Error fetching data:', error));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = (query) => {
    setFilters({
      searchQuery: query,
    });
  };

  const handleCardClick = (userId) => {
    navigate(`/member/${userId}`);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(total / itemsPerPage);
    const pageButtons = [];

    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <button
          key={i}
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        {pageButtons}
      </div>
    );
  };

  const renderFilteredData = () => {
    return (
      <div className="grid">
        {filteredData.map(item => (
          <div className="card" key={item.user_id} onClick={() => handleCardClick(item.user_id)}>
            <img src={item.imageUrl} alt={item.first_name} />
            <p>{`${item.first_name} ${item.last_name}`}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="app">
      <div className="banner">
        <div>
          <img src={femalelanding} alt="male" className='malelanding' />
          <h2>Find your Ideal Man Today.</h2>
        </div>
        <img src={malehero} alt="male" className='malehero' />
      </div>
      <Search onSearch={handleSearch} />
      <h4>Filter By:</h4>
      <div className="filter-bar">
        <select name="countyOfResidence" onChange={handleFilterChange}>
          <option value="">County of Residence</option>
          {countiesWithConstituencies.map(option => (
            <option key={option.county} value={option.county}>{option.county}</option>
          ))}
        </select>
        <select name="height" onChange={handleFilterChange}>
          <option value="">Height</option>
          {heightOptions['Male'].map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="complexion" onChange={handleFilterChange}>
          <option value="">Complexion</option>
          {complexionOptions['Male'].map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="bodyShape" onChange={handleFilterChange}>
          <option value="">Body Shape</option>
          {bodyShapeOptions['Male'].map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="ageGroup" onChange={handleFilterChange}>
          <option value="">Age Group</option>
          {ageGroupOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="sexuality" onChange={handleFilterChange}>
          <option value="">Sexuality</option>
          {sexualityOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="reason" onChange={handleFilterChange}>
          <option value="">Reason</option>
          {reasonOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="ethnicity" onChange={handleFilterChange}>
          <option value="">Ethnicity</option>
          {ethnicityOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="religion" onChange={handleFilterChange}>
          <option value="">Religion</option>
          {religionOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        <select name="countryOfResidence" onChange={handleFilterChange}>
          <option value="">Nationality</option>
          {countryOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      {renderFilteredData()}
      {renderPagination()}
    </div>
  );
};

export default FemaleHomepage;
