import React, { useEffect, useState } from 'react';
import '../header/header.css'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Cookies from 'js-cookie';
// const backend_url = process.env.REACT_APP_BACKEND_URL;
const backend_url = "https://finaldating-jlkh.onrender.com/";

const Notification = ({ socket, storedNotifications }) => {
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState('');
  const [requester, setRequester] = useState(null);
  const { currentUserId } = useAuth();
  const token = Cookies.get('token');

  const navigate = useNavigate();
  useEffect(() => {
    const handleReceiveRequest = async (data) => {
      const username = await fetchUsername(data.requester);
      setRequester(data.requester);
      setNotification(`Connection request from ${username}`);
      setNotifications((prev) => [...prev, { type: 'request', from: data.requester, username, processed: false }]);
    };

    const handleRequestAccepted = async (data) => {
      const username = await fetchUsername(data.receiver);
      setNotification(`Your request to ${username} was accepted.`);
      setNotifications((prev) => [...prev, { type: 'accept', from: data.receiver, username, processed: false }]);
    };

    const handleRequestRejected = async (data) => {
      const username = await fetchUsername(data.receiver);
      setNotification(`Your request to ${username} was rejected.`);
      setNotifications((prev) => [...prev, { type: 'reject', from: data.receiver, username, processed: false }]);
    };

    const handleStoredNotifications = async (data) => {
      const updatedNotifications = await Promise.all(data.map(async (notif) => {
        const username = await fetchUsername(notif.from);
        return { ...notif, username, processed: false };
      }));
      setNotifications(updatedNotifications);
    };

    socket.on('receive_request', handleReceiveRequest);
    socket.on('request_accepted', handleRequestAccepted);
    socket.on('request_rejected', handleRequestRejected);
    socket.on('stored_notifications', handleStoredNotifications);

    return () => {
      socket.off('receive_request', handleReceiveRequest);
      socket.off('request_accepted', handleRequestAccepted);
      socket.off('request_rejected', handleRequestRejected);
      socket.off('stored_notifications', handleStoredNotifications);
    };
  }, [socket, currentUserId]);

  const fetchUsername = async (userId) => {
    
    try {
      const response = await fetch(`${backend_url}/profile/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const data = await response.json();
      return `${data.user.first_name} ${data.user.last_name}`;
    } catch (error) {
      console.error('Error fetching username:', error);
      return 'Unknown';
    }
  };

  const handleCardClick = (Id) => {
    navigate(`/member/${Id}`);
  };

  const acceptRequest = async (from) => {
    try {
      const response = await fetch(`${backend_url}/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ user1_id: currentUserId, user2_id: from }),
      });

      if (!response.ok) {
        throw new Error('Failed to store connection');
      }

      socket.emit('accept_request', { receiver: currentUserId, requester: from });
      setNotification('');
      setRequester(null);
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.type === 'request' && notif.from === from ? { ...notif, processed: true } : notif
        )
      );
    } catch (error) {
      console.error('Error accepting request:', error);
    }
  };

  const rejectRequest = (from) => {
    socket.emit('reject_request', { receiver: currentUserId, requester: from });
    setNotification('');
    setRequester(null);
    setNotifications((prev) =>
      prev.map((notif) =>
        notif.type === 'request' && notif.from === from ? { ...notif, processed: true } : notif
      )
    );
  };

  return (
<div className='not'>
      <h2>Notifications</h2>
      {notification && (
        <>
          <p>{notification}</p>
          {requester && (
            <div className='notification-btns'>
              <button onClick={() => acceptRequest(requester)}>Accept</button>
              <button onClick={() => rejectRequest(requester)}>Reject</button>
            </div>
          )}
        </>
      )}
      <ul>
        {!notifications.length ? (
          storedNotifications && storedNotifications.map((notif, index) => (
            <li key={index} className='notitem'>
              {notif.type === 'request' && `Connection request from ${notif.username}`}
              {notif.type === 'accept' && `${notif.username} accepted your request`}
              {notif.type === 'reject' && `${notif.username} rejected your request`}
              {notif.type === 'request' && !notif.processed && (
                <div className='notification-btns'>
                  <button onClick={() => handleCardClick(notif.from)}>View Profile</button>
                  <button onClick={() => acceptRequest(notif.from)}>Accept</button>
                  <button onClick={() => rejectRequest(notif.from)}>Reject</button>
                </div>
              )}
            </li>
          ))
        ) : (
          notifications.map((notif, index) => (
            <li key={index} className='notitem'>
               {notif.type === 'request' && `Connection request from ${notif.username}`}
              {notif.type === 'accept' && `${notif.username} accepted your request`}
              {notif.type === 'reject' && `${notif.username} rejected your request`}
              {notif.type === 'request' && !notif.processed && (
                <div className='notification-btns'>
                  <button onClick={() => handleCardClick(notif.from)}>View Profile</button>
                  <button onClick={() => acceptRequest(notif.from)}>Accept</button>
                  <button onClick={() => rejectRequest(notif.from)}>Reject</button>
                </div>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Notification;












