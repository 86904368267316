import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './resetPassword.css';

const backend_url = process.env.REACT_APP_BACKEND_URL;

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    fetch(`${backend_url}/reset_password/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password, confirmPassword }),
    })
    .then(response => response.json())
    .then(data => {
      setMessage(data.message || 'Password reset successfully.');
      if (data.message === 'Your password has been reset successfully') {
        setTimeout(() => navigate('/login'), 3000);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again later.');
    });
  };

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Reset Password</h2>
      <div className="form-group">
        <input name="password" type="password" placeholder="New Password" onChange={handlePasswordChange} required />
      </div>
      <div className="form-group">
        <input name="confirmPassword" type="password" placeholder="Confirm New Password" onChange={handleConfirmPasswordChange} required />
      </div>
      <button type="submit">Reset Password</button>
      {message && <p className="message">{message}</p>}
    </form>
  );
};

export default ResetPassword;
